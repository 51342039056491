.project-details-image-collection {
    width: 1100px;
}

@media (max-width: 1200px) {
    .project-details-image-collection {
        width: 750px;
    }
}

@media (max-width: 850px) {
    .project-details-image-collection {
        width: 80vw;
    }
}