@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

body {
  font-family: 'Lato', sans-serif;
}

button {
  border-radius: 0px;
  border: 0;
  padding: 10px 15px;
  cursor: pointer;
}

img:active {
	pointer-events: none;
}

.italic {
  font-style: italic;
}