.menu-bar-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 700px;
  padding-top: 50px;
  padding-bottom: 20px;
}

@media (max-width: 850px) {
  .menu-bar-buttons {
    flex-direction: column;
    width: 80vw;
    gap: 0;
    padding: 20px 0 0 0;
  }
}