.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 95vh;
}

.app-picture {
  opacity: 50%;
  max-width: 1000px;
  width: 60%;
  height: auto;
}