.about {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-content {
    width: 700px;
    font-size: large;
}

.about-elem {
    margin: 20px 0;
}

.about-title {
    font-size: xx-large;
    font-weight: bolder;
    margin: 50px 0;
}

.about-email {
    font-size: x-large;
    color: grey;
    text-decoration: none;
}

.about-logo {
    width: 310px;
    height: 310px;
    object-fit: cover;
}

.about-picture {
    width: 150px;
    height: 150px;
    object-fit: cover;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.about-pictures {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 50px 0;
}

.about-text {
    color: grey;
    margin: 20px 0;
}

@media (max-width: 850px) {
    .about-content {
        width: 80vw;
    }
}

@media (max-width: 600px) {
    .about-logo {
        width: 200px;
        height: 200px;
    }
    
    .about-picture {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 550px) {
    .about-title {
        font-size: 6vw;
    }

    .about-email {
        font-size: 5vw;
    }

    .about-content {
        font-size: 4vw;
    }
}

@media (max-width: 450px) {
    .about-logo {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 385px) {
    .about-picture {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 265px) {
    .about-logo {
        width: 100px;
        height: 100px;
    }

    .about-picture {
        width: 40px;
        height: 40px;
    }
}