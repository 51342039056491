.main-gallery-img {
    width: 750px;
    height: 750px;
    object-fit: cover;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.main-gallery-img:hover {
    filter: none;
    -webkit-filter: grayscale(0);
    transition: 0.2s;
}

.main-gallery-img-with-more-content:hover {
    cursor: pointer;
}

@media (max-width: 850px) {
    .main-gallery-img {
        width: 500px;
        height: 500px;
    }
}

@media (max-width: 600px) {
    .main-gallery-img {
        width: 300px;
        height: 300px;
    }
}

@media (max-width: 350px) {
    .main-gallery-img {
        width: 200px;
        height: 200px;
    }
}