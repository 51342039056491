.cookies-banner {
  background: rgb(206, 204, 204);
  width: 100%;
  height: 80px;
  text-align: center;
  padding: 10px;
  position: fixed;
  top: 98%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cookies-banner-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 35px;
  padding: 10px 20px;
  height: 30px;
}

@media (max-width: 700px) {
  .cookies-banner {
    height: 210px;
  }

  .cookies-banner-content {
    height: 80px;
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 531px) {
  .cookies-banner {
    height: 300px;
  }

  .cookies-banner-content {
    height: 120px;
  }
}

@media (max-width: 250px) {
  .cookies-banner {
    height: 470px;
  }

  .cookies-banner-content {
    height: 200px;
  }
}