.main-btn {
  display: grid;

  .m-btn-element {
    font-size: x-large;
    font-weight: 700;
    grid-row: 1;
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .m-btn-title {
    visibility: visible;
    opacity: 1;
  }

  .m-btn-icon {
    visibility: hidden;
    opacity: 0;
  }
}

.main-btn:hover {
  .m-btn-title {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
  }

  .m-btn-icon {
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
  }
}

@media (max-width: 550px) {
  .main-btn {
    .m-btn-element {
      font-size: medium;
      font-weight: 700;
      grid-row: 1;
      grid-column: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}